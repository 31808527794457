import React from 'react'

const HomeAbout = () => {
    return (
        <section className="section mb-5">
            <div className="container">

                <div className="row justify-content-center text-center mb-5">
                    <div className="col-md-12" data-aos="fade-up">
                        <h2 className="section-heading mb-5">CORE LASER IS -
                            INNOVATION TO THE CORE</h2>
                        <p className='fw-bold '>A groundbreaking core training system based on your body’s natural movements that provides lasting relief from lower back pain. </p>
                        <p >When you understand your body’s recovery process, you can heal faster with lasting relief. Our goal- oriented tasks are designed to engage both the body and the mind, helping you heal quickly and securely. </p>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
                        <div className="feature-1 text-center">
                            <div className="wrap-icon icon-1">
                                <i className="bi bi-people"></i>
                            </div>
                            <h3 className="mb-3">Innovative visual guidance </h3>
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="feature-1 text-center">
                            <div className="wrap-icon icon-1">
                                <i className="bi bi-brightness-high"></i>
                            </div>
                            <h3 className="mb-3">Expert-level technology </h3>
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="feature-1 text-center">
                            <div className="wrap-icon icon-1">
                                <i className="bi bi-bar-chart"></i>
                            </div>
                            <h3 className="mb-3">Safe, low-impact recovery</h3>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default HomeAbout
