import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const CTA = () => {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = () => {
        // Create the headers
        var myHeaders = new Headers();
        myHeaders.append("token", "");
        myHeaders.append("Content-Type", "application/json");

        // Create the request body
        var raw = JSON.stringify({
            "email": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // Make the API call
        // fetch("http://192.168.1.53:3000/news/add-news", requestOptions)
        fetch("https://api.corelaser.co/news/add-news", requestOptions)
            .then(response => response.json())
            .then(result => {
                // Handle the API response here
                console.log(result);
                setResponseMessage(result.message);
                setEmail('')
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="section cta-section py-5 mt-5">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} className="me-auto text-center text-md-start mb-5 mb-md-0">
                        <h2>Subscribe to receive news and updates.</h2>
                    </Col>
                    <Col md={4} className="form-group mt-3 mt-md-0">
                        <Form.Control
                            type="email"
                            className="form-control rounded"
                            placeholder='Enter your email'
                            name="email"
                            id="email"
                            required=""
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </Col>
                    <Col md={2} className="form-group mt-3 mt-md-0">
                        <Button
                            variant="outline-login"
                            style={{ padding: '5px 15px' }}
                            onClick={handleSubmit}
                        >
                            Subscribe
                        </Button>
                    </Col>
                </Row>
                {responseMessage && (
                    <Row className="mt-3">
                        <Col>
                            <p>{responseMessage}</p>
                        </Col>
                    </Row>
                )}
            </Container>
        </section>
    )
}

export default CTA;