import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer'
import Header from '../components/Header'
import { MakePaymentWeb, getTerminal } from '../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';

const SubscribNow = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!localStorage.getItem("token")) {
      navigate('/sign-in')
    }
  }, []);
  const location = useLocation();

  // console.log("data from parent -- ",localStorage.getItem('userID'))

  // const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  const [response, setResponse] = useState('');
  const [terminal, setTerminal] = useState('')
  const [err, setErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const navigate = useNavigate();
  const [pricingData, setPricingData] = useState(location.state)
  const [formData, setFormData] = useState({
    cardNumber: '',
    amount: '',
    action: '',
    billingFirstName: '',
    billingLastName: '',
    billingCompanyName: '',
    cardExpMonth: '',
    cardExpYear: '',
    cvv: '',
    cardCvv: '',
    avs: '',
    billingAddress: '',
    billingZip: '',

  });
  useEffect(() => {
    get_terminal()
  }, []);

  const get_terminal = () => {
    getTerminal("get-payment-terminal", '')
      .then((res) => {
        console.log(res, "get-payment-terminal res");
        if (res.success === true) {
          setTerminal(res.result.results[0])
          console.log("Ok");
        } else {
          // alert(res.message).
          console.log("not Ok");
        }
      }).catch((e) => {
        console.log("response : ", e);

      })
  }

  const handleSubmit = async (e) => {

    setIsLoading(true)

    const validationMessages = {
      cardNumber: formData.cardNumber !== '' ? '' : 'Card number is required',
      billingFirstName: formData.billingFirstName !== '' ? '' : 'Name is required',
      billingCompanyName: formData.billingCompanyName !== '' ? '' : 'Company name is required',
      cardExpMonth: formData.cardExpMonth !== '' ? '' : 'Card expiry month is required.',
      cardExpYear: formData.cardExpYear !== '' ? '' : 'Card expiry year is required',
      cardCvv: formData.cardCvv !== '' ? '' : 'Card CVV is required',
      billingAddress: formData.billingAddress !== '' ? '' : 'Address is required',
      billingZip: formData.billingZip !== '' ? '' : 'Zip code is required',

    };

    const formValid = Object.values(validationMessages).every(message => message === '');
    setValidationMessages(validationMessages)

    if (formValid) {

      var myHeaders = new Headers();
      myHeaders.append("token", localStorage.getItem('token'));
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


      var urlencoded = new URLSearchParams();
      urlencoded.append("user_Id", localStorage.getItem('userID'));

      // action: "CHARGE",
      // cvv: "ON",
      // avs: "ADDRESS_AND_ZIP"

      urlencoded.append("plan_name", formData.plan_name);
      urlencoded.append("amount", formData.amount);
      urlencoded.append("cardNumber", formData.cardNumber);
      urlencoded.append("action", "CHARGE");
      urlencoded.append("amountBase", formData.amountBase);
      urlencoded.append("billingFirstName", formData.billingFirstName);
      // urlencoded.append("billingLastName", formData.billingLastName);
      urlencoded.append("billingCompanyName", formData.billingCompanyName);
      urlencoded.append("cardExpMonth", formData.cardExpMonth);
      urlencoded.append("cardExpYear", formData.cardExpYear);
      urlencoded.append("cvv", "ON");
      urlencoded.append("cardCvv", formData.cardCvv);
      urlencoded.append("avs", "ADDRESS_AND_ZIP");
      urlencoded.append("billingAddress", formData.billingAddress);
      urlencoded.append("billingZip", formData.billingZip);

      console.log(localStorage.getItem('userID'), "FormData")

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      // fetch("http://192.168.1.53:3000/payment/make-payment", requestOptions)
      fetch("https://api.corelaser.co/payment/make-payment", requestOptions)
        .then(response => response.json())
        .then(result => {
          setIsLoading(false)
          setResponse(result)
          setErr(result)
          setFormData({
            cardNumber: '',
            amount: '',
            action: '',
            billingFirstName: '',
            billingLastName: '',
            billingCompanyName: '',
            cardExpMonth: '',
            cardExpYear: '',
            cvv: '',
            cardCvv: '',
            avs: '',
            billingAddress: '',
            billingZip: '',

          })
          console.log("Result", result)
          // navigate("/")
        })
        .catch(error => setErr(error), setIsLoading(false));

      // console.log("this is formdata",formData)
      // MakePaymentWeb("make-payment",formData)
      // .then((res) => {
      //   console.log(res, "res");
      //   if (res.success === true) {
      //     console.log("Ok");
      //   } else {
      //     console.log("not Ok");
      //   }
      // }).catch((e) => {
      //   console.log("response : ", e);

      // })

    } else {
      console.log("Fill all the required details")

      setIsLoading(false)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      plan_name: pricingData?.plan_name,
      amount: pricingData?.amount,
      amountBase: pricingData?.amount,
      user_id: localStorage.getItem('userID'),
      action: "CHARGE",
      cvv: "ON",
      avs: "ADDRESS_AND_ZIP"
    });
  };

  return (
    <>
      <Header />
      <main id='main'>

        <section className="hero-section inner-page">
          <div className="wave">

            <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                  <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                </g>
              </g>
            </svg>

          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center hero-text">
                    <h1 data-aos="fade-up" data-aos-delay="">Subscribe Now</h1>
                    <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Please contact Customer Support for any questions you may have regarding CoreLaser.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <section className="checkout-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="checkout-form" data-aos="fade-up">
                  <h3>Checkout</h3>
                  {terminal === "" ? (
                    <Spinner animation="border" />
                  ) : (
                    <>
                      <div className='row'>
                        <div className='col-md-6'>
                          <h6>Pay to</h6>
                          <h3>{terminal.companyLegal}</h3>
                          <h6>Payment Type</h6>
                          <h6>{terminal.type}</h6>
                        </div>
                        <div className='col-md-6'>
                          <h6>Plan</h6>
                          <h3>{pricingData?.plan_name}</h3>
                          <h6>Amount</h6>
                          <h6>${pricingData?.amount}</h6>
                        </div>
                      </div>

                    </>
                  )}

                  <form>
                    <p>Fill in the details to make a payment</p>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="billingFirstName"><span className='text-danger'>* </span>Name on CARD</label>
                          <input
                            type="text"
                            className="form-control"
                            name="billingFirstName"
                            value={formData.billingFirstName}
                            onChange={handleChange}
                            required
                          />
                          {validationMessages.billingFirstName && <p style={{ color: 'red' }}>{validationMessages.billingFirstName}</p>}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="billingLastName"><span className='text-danger'>* </span>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="billingLastName"
                            value={formData.billingLastName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div> */}
                    </div>



                    <div className="form-group">

                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="cardNumber"><span className='text-danger'>* </span>Card Number</label>
                        <input
                          type="text"
                          className="form-control"
                          inputMode="numeric"
                          name="cardNumber"
                          placeholder="XXXX XXXX XXXX XXXX"
                          value={formData.cardNumber}
                          onChange={handleChange}
                          pattern="[0-9 ]*"
                          maxLength="19"
                          required
                        />
                        {validationMessages.cardNumber && <p style={{ color: 'red' }}>{validationMessages.cardNumber}</p>}
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="cardExpMonth"><span className='text-danger'>* </span>Card Expiry Month</label>
                          <select
                            className="form-control"
                            name="cardExpMonth"
                            value={formData.cardExpMonth}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Month</option>
                            <option value="01">01 - January</option>
                            <option value="02">02 - Fabruary</option>
                            <option value="03">03 - March</option>
                            <option value="04">04 - April</option>
                            <option value="05">05 - May</option>
                            <option value="06">06 - June</option>
                            <option value="07">07 - July</option>
                            <option value="08">08 - August</option>
                            <option value="09">09 - September</option>
                            <option value="10">10 - October</option>
                            <option value="11">11 - November</option>
                            <option value="12">12 - December</option>
                          </select>
                          {validationMessages.cardExpMonth && <p style={{ color: 'red' }}>{validationMessages.cardExpMonth}</p>}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="cardExpYear"><span className='text-danger'>* </span>Card Expiry Year</label>
                          <input
                            type="text"
                            className="form-control"
                            name="cardExpYear"
                            value={formData.cardExpYear}
                            onChange={handleChange}
                            required
                          />
                          {validationMessages.cardExpYear && <p style={{ color: 'red' }}>{validationMessages.cardExpYear}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="cardCvv"><span className='text-danger'>* </span>Card CVV</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cardCvv"
                        maxLength="4"
                        value={formData.cardCvv}
                        onChange={handleChange}
                        pattern="[0-9]*"
                        required
                      />
                      {validationMessages.cardCvv && <p style={{ color: 'red' }}>{validationMessages.cardCvv}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="billingAddress"><span className='text-danger'>* </span>Billing Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="billingAddress"
                        value={formData.billingAddress}
                        onChange={handleChange}
                      />
                      {validationMessages.billingAddress && <p style={{ color: 'red' }}>{validationMessages.billingAddress}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="billingZip"><span className='text-danger'>* </span>Billing Zip</label>
                      <input
                        type="text"
                        className="form-control"
                        name="billingZip"
                        value={formData.billingZip}
                        onChange={handleChange}
                      />
                      {validationMessages.billingZip && <p style={{ color: 'red' }}>{validationMessages.billingZip}</p>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="billingCompanyName"><span className='text-danger'>* </span>Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="billingCompanyName"
                        value={formData.billingCompanyName}
                        onChange={handleChange}
                      />
                      {validationMessages.billingCompanyName && <p style={{ color: 'red' }}>{validationMessages.billingCompanyName}</p>}
                    </div>


                    <button
                      className="btn btn-primary btn-block mt-3 mb-3"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading === true ? (
                        <Spinner animation="border" />
                      ) : "Submit"
                      }
                    </button>

                  </form>

                  {/* {response?.result?.Payment_Response?.response?.approved === true ? (
                    <div className="text-success mt-3">Payment Approved</div>
                  ) : response?.result?.Payment_Response?.errors[0]?.message ? (
                    //""
                    <div className="text-danger mt-3">{response?.result?.Payment_Response?.errors[0]?.message}</div>
                  ) :
                  // <div className="text-danger mt-3">Payment Declined</div>
                  ""
                } */}

                  {response?.result?.Payment_Response?.response?.approved === false ? (
                    <div className="text-danger mt-3">{response?.result?.Payment_Response?.response?.message}</div>
                  ) : response?.result?.Payment_Response?.message ?
                    // ""
                    <div className="text-danger mt-3">DONE - {response?.result?.Payment_Response?.message}</div>
                    :
                    // <div className="text-danger mt-3">Payment Declined</div>
                    ""
                  }
                  {err.message === "Payment Successful" ? navigate('/payment-success', { state: { paymentDtails: err } }) : <p className='text-danger'>{err.message}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default SubscribNow
