// import React, { useState } from 'react'
// import Footer from '../components/Footer'
// import Header from '../components/Header'
// import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Row from 'react-bootstrap/Row';
// const Signin = () => {
//     const [validated, setValidated] = useState(false);
//     const [data, setData] = useState({
//         email: '',
//         password: ''
//     })
//     const handleSubmit = async (event) => {
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.preventDefault();
//             event.stopPropagation();
//         } else {
//             event.preventDefault();

//             try {

//                 const response = await signIn({
//                     email: data.email,
//                     password: data.password,
//                 });


//                 console.log('Sign-in successful', response);



//             } catch (error) {

//                 console.error('Error signing in:', error.message);
//             }
//         }

//         setValidated(true);
//     };

//     return (
//         <>
//             <Header />
//             <main id='main'>

//                 <section className="hero-section inner-page">
//                     <div className="wave">

//                         <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
//                             <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//                                 <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
//                                     <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
//                                 </g>
//                             </g>
//                         </svg>

//                     </div>

//                     <div className="container">
//                         <div className="row align-items-center">
//                             <div className="col-12">
//                                 <div className="row justify-content-center">
//                                     <div className="col-md-6 text-center hero-text">
//                                         <h1 data-aos="fade-up" data-aos-delay="">Login</h1>
//                                         <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Log in now and let's keep moving forward with LaserGym. Your personal digital physiotherapist is ready when you are.</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </section>
//                 <section className="section my-5">
//                     <div className="container">
//                         <div className="row justify-content-center">

//                             <div className="col-md-4 mb-5 mb-md-0" data-aos="fade-up">
//                                 <Form noValidate validated={validated} onSubmit={handleSubmit}>
//                                     <Row className="mb-3">
//                                         <Form.Group as={Col} md="12" controlId="validationCustom01">
//                                             <Form.Label>First name</Form.Label>
//                                             <Form.Control
//                                                 required
//                                                 type="text"
//                                                 placeholder="First name"
//                                                 defaultValue="Mark"
//                                             />
//                                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                                         </Form.Group>
//                                         <Form.Group as={Col} md="12" controlId="validationCustom02">
//                                             <Form.Label>Last name</Form.Label>
//                                             <Form.Control
//                                                 required
//                                                 type="text"
//                                                 placeholder="Last name"
//                                                 defaultValue="Otto"
//                                             />
//                                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                                         </Form.Group>

//                                     </Row>

//                                     <Form.Group className="mb-3 text-end">
//                                         <Form.Label>Forgot Password?</Form.Label>
//                                     </Form.Group>
//                                     <Button type="submit" className='w-100'>Submit form</Button>
//                                 </Form>
//                             </div>

//                         </div>
//                     </div>
//                 </section>
//             </main>
//             <Footer />
//         </>
//     )
// }

// export default Signin

import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { forgotPassword, resetPassword, signIn, verifyOTP } from '../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ForgotPassword from '../components/ForgotPassword';
import CheckOTP from '../components/CheckOTP';
import ResetPassword from '../components/ResetPassword';

const Signin = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState({});
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [invalidCredentialError, setInvalidCredentialError] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [isOTPSending, setIsOTPSending] = useState(false)
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [showOTP, setShowOTP] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [email, setEmail] = useState("")
    const [otpError, setOTPError] = useState("")
    const [otpVerifyError, setOTPVerifyError] = useState("")
    const [resetPassError, setResetPassError] = useState("")

    const navigate = useNavigate();

    const openForgotPasswordModal = () => {
        setShowForgotPasswordModal(true);
    };

    const closeModals = () => {
        setShowResetPassword(false)
        setShowForgotPasswordModal(false)
        setShowOTP(false)
        setOTPError("")
        setOTPVerifyError("")
        setResetPassError("")
    }

    const handleChangeEmail = (event) => {
        const eMail = event.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(data.email);
        setData({ ...data, email: eMail });
        setEmailError(isValidEmail ? "" : "Invalid Email Address");
    }

    const handleChangePassword = (event) => {
        const passWord = event.target.value;
        const passwordRegex = /^.{8,}$/;
        const isValidPassword = passwordRegex.test(data.password);
        setData({ ...data, password: passWord });
        setPasswordError(isValidPassword ? "" : "Password Must be Atleast 8 Characters");
    }

    const handleSubmit = async () => {

        const newErrors = {};

        if (!data.email.trim()) {
            newErrors.email = 'Email is required';
        }
        if (!data.password.trim()) {
            newErrors.password = 'Password is required';
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true)
        signIn("/user-login", data)
            .then((res) => {
                console.log(res, "res");
                if (res.success === true) {
                    console.log("run", res);
                    localStorage.setItem("email", res.result.email);
                    localStorage.setItem("token", res.result.token);
                    localStorage.setItem("username", res.result.name);
                    localStorage.setItem("firstName", res.result.name.split(' ')[0]);
                    localStorage.setItem("lastName", res.result.name.split(' ').slice(1).join(' '));
                    localStorage.setItem("plan", res.result.membership);
                    localStorage.setItem("userID", res.result._id);
                    console.log("res-true : ", res);
                    setLoading(false)
                    navigate("/");

                } else {
                    console.log("Res : ", res);
                    setLoading(false)

                    setInvalidCredentialError(res.error);

                }
            }).catch((e) => {
                console.log("response : ", e);
                setLoading(false)

            })
        // }

        setValidated(true);
    };

    const handleSendOTP = async (email) => {
        setIsOTPSending(true)
        setEmail(email)
        forgotPassword("forgot-password", { email: email })
            .then((res) => {
                console.log(res, "res");
                if (res.success === true) {
                    console.log("run", res);
                    setIsOTPSending(false)
                    setShowForgotPasswordModal(false)
                    setShowOTP(true)
                } else {
                    console.log("Res : ", res);
                    setIsOTPSending(false)
                    setOTPError(res.error);

                }
            }).catch((e) => {
                console.log("response : ", e);
                setIsOTPSending(false)
                setOTPError(e);
            })

    }

    const OTPverify = async (emailAndOTP) => {
        setLoading(true)
        verifyOTP("check-otp-mail", emailAndOTP)
            .then((res) => {
                console.log(res, "res");
                if (res.success === true) {
                    console.log("run", res);
                    setLoading(false)
                    setShowResetPassword(true)
                } else {
                    console.log("Res : ", res);
                    setLoading(false)
                    setOTPVerifyError(res.error);

                }
            }).catch((e) => {
                console.log("response : ", e);
                setOTPVerifyError(e);
                setLoading(false)
            })

    }

    const changePassword = async (emailAndOTP) => {
        setLoading(true)
        resetPassword("reset-password", emailAndOTP)
            .then((res) => {
                console.log(res, "res");
                if (res.success === true) {
                    console.log("run", res);
                    setLoading(false)
                    setOTPError("")
                    setOTPVerifyError("")
                    setResetPassError("")

                    // alert("Your password reset successfully...")

                } else {
                    console.log("Res : ", res);
                    setLoading(false)
                    setResetPassError(res.message);
                }
            }).catch((e) => {
                console.log("response : ", e);
                setResetPassError(e);
                setLoading(false)
            })

    }

    return (
        <>
            <Header />
            <main id='main'>

                <section className="hero-section inner-page">
                    <div className="wave">

                        <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 text-center hero-text">
                                        <h1 data-aos="fade-up" data-aos-delay="">Login</h1>
                                        <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Log in now and let's keep moving forward with LaserGym. Your personal digital physiotherapist is ready when you are.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section my-5">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-md-4 mb-5 mb-md-0" data-aos="fade-up">
                                <Form noValidate validated={validated} >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" className='mb-3' controlId="validationCustom01">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email"
                                                value={data.email}
                                                onChange={handleChangeEmail}

                                            />

                                            {errors.email && <span className="text-danger">{errors.email}</span>}


                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                required
                                                type="password"
                                                placeholder="Password"
                                                value={data.password}
                                                onChange={handleChangePassword}

                                            />

                                            {errors.password && <span className="text-danger">{errors.password}</span>}

                                        </Form.Group>

                                    </Row>

                                    <Form.Group className="mb-2 d-flex justify-content-between">
                                        {invalidCredentialError && (
                                            <Form.Text className='text-danger'>{invalidCredentialError}</Form.Text>
                                        )}
                                        {/* <Form.Label>Forgot Password?</Form.Label> */}

                                    </Form.Group>
                                    <div className='text-end mb-2' style={{ cursor: "pointer" }} onClick={openForgotPasswordModal}>Forgot password?</div>
                                    <Button type="button" onClick={() => handleSubmit()} className='w-100'>
                                        {
                                            isLoading
                                                ?
                                                <Spinner animation="border" />
                                                :
                                                "Login"
                                        }
                                    </Button>

                                    <label className='mt-3 mx-1'>Don't have an account? <Link to="/sign-up">Signup</Link></label>

                                </Form>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <ForgotPassword error={otpError} handleSendOTP={handleSendOTP} isLoading={isOTPSending} show={showForgotPasswordModal} onHide={() => closeModals()} />
            <CheckOTP error={otpVerifyError} OTPverify={OTPverify} email={email} show={showOTP} onHide={() => closeModals()} />
            <ResetPassword error={resetPassError} email={email} changePassword={changePassword} show={showResetPassword} onHide={() => closeModals()} />
        </>
    )
}

export default Signin

