import React from 'react'

const HomeFeature = () => {
    return (
        <>
            <section className="section mb-3 pb-5">

                <div className="container">
                    <div className="row justify-content-center text-center mb-5" data-aos="fade">
                        <div className="col-md-12 mb-2">
                            <h2 className="section-heading mb-5">No More Low Back Pain</h2>
                            <img src="assets/img/feature.png" alt="Image" className="img-fluid" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="step">
                                <span className="number">01</span>
                                <h3>GET MOVING</h3>
                                <p>Ignite your Core with 5 minutes of easy to follow exercises</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="step">
                                <span className="number">02</span>
                                <h3>GET STRONG</h3>
                                <p>Build strength, increase flexibility and engage your lower back</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="step">
                                <span className="number">03</span>
                                <h3>EVERYDAY HABIT</h3>
                                <p>With daily use, you will rewire your Neuro pathways for a pain free future</p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 me-auto">
                            <h2 className="section-heading my-5">Get moving again – Pain Free!</h2>

                            <p className="mb-4">Core Laser is committed to improving people’s lives through innovative technology and safe and low-impact recovery and we push the envelope in order to develop and implement better and more effective solutions.</p>
                            {/* <p><a href="#" className="btn btn-primary">Download Now</a></p> */}
                        </div>
                        <div className="col-md-6 text-center" data-aos="fade-left">
                            <img src="assets/img/home.png" width={200} alt="Image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeFeature

