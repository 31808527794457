import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [validated, setValidated] = useState(false);
  const [formStatus, setFormStatus] = useState(null);

  const navigate = useNavigate()
  const takeToTop = (redirectTo) => {

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    if (redirectTo === "home") {
      navigate('/')
    } else if (redirectTo === "about") {
      navigate('/about')
    } else if (redirectTo === "pricing") {
      navigate('/pricing')
    } else {
      navigate('/contact')
    }
  }

  const handleButtonClick = async () => {
    // Validate the form fields
    const form = document.getElementById('contactForm');
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    // Submit the form data to your backend API.
    try {
      // const response = await fetch('http://192.168.1.53:3000/contact/add-conatact', {
      const response = await fetch('https://api.corelaser.co/contact/add-conatact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormStatus('success');
        // Clear the form data after a successful submission.
        console.log(response.json());
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      } else {
        throw new Error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error:', error);
      setFormStatus('error');
    }
  };
  return (
    <>
      <Header takeToUp={takeToTop} />
      <main id='main'>

        <section className="hero-section inner-page">
          <div className="wave">

            <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                  <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                </g>
              </g>
            </svg>

          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center hero-text">
                    <h1 data-aos="fade-up" data-aos-delay="">Get in touch</h1>
                    <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Please contact Customer Support for any questions you may have regarding CoreLaser.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <section className="section my-5">
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} mb={5} mb-md={0} data-aos="fade-up">
                <Form
                  noValidate
                  validated={validated}
                  id="contactForm" // Add an ID to the form
                  className="php-email-form"
                >
                  <Row>
                    <Col md={6} className="form-group">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your name"
                        required
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your name.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} className="form-group mt-3 mt-md-0">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your email"
                        required
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={12} className="form-group mt-3">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                        value={formData.subject}
                        onChange={(e) =>
                          setFormData({ ...formData, subject: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a subject.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={12} className="form-group mt-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        required
                        value={formData.message}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your message.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={12} className="mb-3">
                      {formStatus === 'success' && (
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      )}
                      {formStatus === 'error' && (
                        <div className="error-message">
                          An error occurred. Please try again later.
                        </div>
                      )}
                    </Col>
                    <Col md={12} className="form-group">
                      <Button
                        type="button" // Change the button type to "button" to prevent form submission
                        className="btn btn-primary d-block "
                        onClick={handleButtonClick} // Attach the click handler
                      >
                        Send Message
                      </Button>
                    </Col>
                    {formStatus === "success" && (

                      <Col>
                        <p>Form submitted successfully</p>
                      </Col>

                    )}
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer takeToUp={takeToTop} />
    </>
  )
}

export default Contact
