import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hero from '../components/Hero';
import HomeAbout from '../components/HomeAbout';
import HomeFeature from '../components/HomeFeature';
import Testimonials from '../components/Testimonials';
export const socket = io('https://api.corelaser.co');
// export const socket = io('http://192.168.1.53:3000');


const Home = () => {
    const navigate = useNavigate()
    const [messages, setMessages] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);

    const takeToTop = (redirectTo) => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        if (redirectTo === "home") {
            navigate('/')
        } else if (redirectTo === "about") {
            navigate('/about')
        } else if (redirectTo === "pricing") {
            navigate('/pricing')
        } else {
            navigate('/contact')
        }
    }

    socket.on('connect-to-web-android', (userId) => {

        if (!localStorage.getItem('userID')) {
            alert("please login")
        } else if (localStorage.getItem('userID') !== userId) {
            alert("user id not match of web and app")
        } else {
            // window.open(`/connect-to-app/ios/${userId}`, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
            const popup = window.open(`/connect-to-app/ios/${userId}`, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                // Pop-up blocked
                alert('Popup blocked! Please disable your pop-up blocker for this site to use this feature.');
            }
        }

    });

    socket.on('connect-to-web-ios', (userId) => {
        if (!localStorage.getItem('userID')) {
            alert("please login")
        } else if (localStorage.getItem('userID') !== userId) {
            alert("user id not match of web and app")
        } else {


            const popup = window.open(`/connect-to-app/ios/${userId}`, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                // Pop-up blocked
                alert('Popup blocked! Please disable your pop-up blocker for this site to use this feature.');
            }
            // console.log(socket,'sokcekte');
            // console.log("keyur");
            // window.open(`/connect-to-app/ios/${userId}`,'_blank','fullscreen=yes,location=no,toolbar=no,menubar=no,status=no,scrollbars=no')
        }
        // window.open(`/connect-to-app/ios/${userId}`,'_blank')
        // console.log('connection done ios');
        // navigate('/connect-to-app/ios')
        // navigate(`/connect-to-app/ios/${userId}`)
    });


    return (
        <div>
            <Header takeToUp={takeToTop} />
            <Hero />
            <main id="main">
                <HomeAbout />
                <HomeFeature />
                <Testimonials />
                <CTA />
            </main>
            <Footer takeToUp={takeToTop} />
        </div>
    );
}

export default Home
