import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { signUp } from '../utils/helper'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import moment from 'moment'
const Signup = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [data, setData] = useState({
        email: '',
        password: '',
        name: '',
        phone: '',
        DOB: '',
        gender: '',
        weight: '',
        height: '',
        avatar: '',
    })

    const [errors, setErrors] = useState({});
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");


    const navigate = useNavigate();

    const handleChange = (event) => {
        console.log("value", event.target.value)

        if (event.target.name === "confirmPassword") {
            setConfirmPassword(event.target.value)
        }
        setData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
        }));
    };


    const handleSubmit = async (event) => {
        console.log("this data 1: - >", data)
        event.preventDefault();
        setLoading(true)

        const newErrors = {};
        if (!data.name.trim()) {
            newErrors.name = ' Name is required';
        }
        if (!data.email.trim()) {
            newErrors.email = 'Email is required';
        }
        if (!data.phone.trim()) {
            newErrors.phone = 'Phone is required';
        }
        if (!data.DOB.trim()) {
            newErrors.DOB = 'Date is required';
        }
        if (!data.password.trim()) {
            newErrors.password = 'Password is required';
        }
        if (!confirmPassword.trim() || confirmPassword !== data.password) {
            newErrors.confirmPassword = 'confirm Password is not matching';
        }
        if (!data.gender.trim()) {
            newErrors.gender = ' Gender is required';
        }
        if (!data.weight.trim()) {
            newErrors.weight = 'Weight is required';
        }
        if (!data.height.trim()) {
            newErrors.height = 'Height is required';
        }

        // Add more validation checks as needed

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false)
            return;
        }

        const modifiedData = {
            ...data,
            DOB: moment(data.DOB, 'DD-MM-YYYY').format('MM/DD/YYYY')
        };


        signUp("user-register-web", modifiedData)

            .then((res) => {
                if (res.success === true) {
                    console.log("message", res.message);
                    console.log("res-true : ", res);
                    localStorage.setItem("email", res.result.email);
                    localStorage.setItem("token", res.result.token);
                    localStorage.setItem("userID", res.result._id);
                    localStorage.setItem("username", res.result.name);
                    localStorage.setItem("firstName", res.result.name.split(' ')[0]);
                    localStorage.setItem("lastName", res.result.name.split(' ').slice(1).join(' '));

                    setLoading(false)
                    navigate("/")

                } else {
                    console.log("Res : ", res);
                    setLoading(false)
                    setErrMsg(res.message ? res.message : res.error)
                }
            }).catch((e) => {
                setLoading(false)
                setErrMsg(e)
                console.log("response : ", e);
            })
    }




    return (
        <>
            <Header />
            <main id='main'>

                <section className="hero-section inner-page">
                    <div className="wave">

                        <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 text-center hero-text">
                                        <h1 data-aos="fade-up" data-aos-delay="">Signup</h1>
                                        <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Sign up today and embrace a healthier, more active life with LaserGym. Let's get moving!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section my-5">
                    <div className="container">


                        <div className="row justify-content-center">

                            <div className="col-md-8 mb-5 mb-md-0" data-aos="fade-up">
                                <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                    <div className="row">
                                        <div className="col-md-12 mb-3 form-group">
                                            <label for="name">Name</label>
                                            <input type="text" name="name" onChange={handleChange} value={data.name} className="form-control" id="name" required />
                                            {errors.name && <span className="text-danger">{errors.name}</span>}
                                        </div>
                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Mobile</label>
                                            <input type="text" className="form-control" onChange={handleChange} value={data.phone} name="phone" id="phone" required />
                                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                        </div>
                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Email</label>
                                            <input type="email" className="form-control" onChange={handleChange} value={data.email} name="email" id="email" required />
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>

                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label>Gender</label>
                                            <div className='d-flex justify-content-between'>
                                                <label className="p-1">
                                                    <input
                                                        className="p-1"
                                                        type="radio"
                                                        name="gender"
                                                        value="Male"
                                                        checked={data.gender === 'Male'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    Male
                                                </label>
                                                <label className="p-1">
                                                    <input
                                                        className="p-1"
                                                        type="radio"
                                                        name="gender"
                                                        value="Female"
                                                        checked={data.gender === 'Female'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    Female
                                                </label>
                                                <label className="p-1">
                                                    <input
                                                        className="p-1"
                                                        type="radio"
                                                        name="gender"
                                                        value="Other"
                                                        checked={data.gender === 'Other'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    Other
                                                </label>
                                            </div>
                                            {errors.gender && <span className="text-danger">{errors.gender}</span>}
                                        </div>

                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Age</label>
                                            <input type="date"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={data.DOB}
                                                name="DOB"
                                                id="DOB"
                                                required />
                                            {errors.DOB && <span className="text-danger">{errors.DOB}</span>}
                                        </div>

                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Weight</label>
                                            <input type="text" className="form-control" onChange={handleChange} value={data.weight} name="weight" id="weight" required />
                                            {errors.weight && <span className="text-danger">{errors.weight}</span>}
                                        </div>
                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Height</label>
                                            <input type="text" className="form-control" onChange={handleChange} value={data.height} name="height" id="height" required />
                                            {errors.height && <span className="text-danger">{errors.height}</span>}
                                        </div>
                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Password</label>
                                            <input type="password" className="form-control" onChange={handleChange} value={data.password} name="password" id="password" required />
                                            {errors.password && <span className="text-danger">{errors.password}</span>}
                                        </div>
                                        <div className="col-md-6 form-group mb-3 mt-md-0">
                                            <label for="name">Confirm Password</label>
                                            <input type="password" className="form-control" onChange={handleChange} value={confirmPassword} name="confirmPassword" id="confirmPassword" required />
                                            {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
                                        </div>


                                        <div className="col-md-12 mb-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>

                                        {errMsg && <p className='text-danger'>{errMsg}</p>}

                                        <div className="col-md-12 form-group">
                                            <Button onClick={handleSubmit} className="btn btn-primary d-block ">
                                                {
                                                    isLoading
                                                        ?
                                                        <Spinner animation="border" />
                                                        :
                                                        "Register"
                                                }
                                            </Button>
                                            <label className='mt-3'>Already have an account? <Link to="/sign-in">Login</Link></label>

                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default Signup
