import React from 'react'
import { Link } from 'react-router-dom'

const Footer = ({ takeToUp }) => {
    console.log("Function-->", takeToUp)

    return (
        <>
            <footer className="footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <h3>About CoreLaser</h3>
                            <p>Despite the overwhelming number of back pain solutions on the market, none genuinely understand how your body reacts to movement during the recovery process, but WE do. </p>
                            <p className="social">
                                <a className='me-1' href="https://twitter.com/corelaser"><span className="bi bi-twitter"></span></a>
                                <a className='me-1' href="https://www.facebook.com/CoreLaserByLaserGym/"><span className="bi bi-facebook"></span></a>
                                <a className='me-1' href="https://www.linkedin.com/company/laser-gym"><span className="bi bi-linkedin"></span></a>
                            </p>
                        </div>
                        <div className="col-md-7 ms-auto">
                            <div className="row site-section pt-0">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Navigation</h3>
                                    <ul className="list-unstyled">
                                        {/* <li onClick={() => takeToTop}><Link to="/">Home</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/about">About</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/pricing">Pricing</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/contact">Contact</Link></li> */}

                                        <li className="c-pointer" onClick={() => takeToUp('home')}>Home</li>
                                        <li className="c-pointer" onClick={() => takeToUp('about')}>About</li>
                                        <li className="c-pointer" onClick={() => takeToUp('pricing')}>Pricing</li>
                                        <li className="c-pointer" onClick={() => takeToUp('contact')}>Contact</li>
                                    </ul>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Services</h3>
                                    <ul className="list-unstyled">
                                        <li><Link to="/terms">Terms and Condition</Link></li>
                                        {/* <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                             
                                        <li><Link to="/business">Business</Link></li> */}
                                    </ul>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Downloads</h3>
                                    <ul className="list-unstyled w-75">
                                        <li><a href="#"><img className='img-fluid' src='assets/img/apple.png' /></a></li>
                                        <li><a href="#"><img className='img-fluid ' src='assets/img/google.png' /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center text-center">
                        <div className="col-md-7">
                            <p className="copyright">&copy; {new Date().getFullYear()} Copyright CoreLaser. All Rights Reserved</p>
                        </div>
                    </div>

                </div>
            </footer>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </>

    )
}

export default Footer
