import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";


const Terms = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  const login = () => {
    navigate('/sign-in')
  }
  const logout = () => {
    localStorage.removeItem("email")
    localStorage.removeItem("token")
    localStorage.removeItem("userID")
    navigate('/sign-in')

  }
  const handleMobileNav = () => {
    const navbar = document.querySelector('#navbar');
    const mobileNavToggle = document.querySelector('.mobile-nav-toggle');

    if (navbar && mobileNavToggle) {
      navbar.classList.toggle('navbar-mobile');
      mobileNavToggle.classList.toggle('bi-list');
      mobileNavToggle.classList.toggle('bi-x');
    }
  };
  return (
    <>
      {/* <Header /> */}
      <header id="header" className="fixed-top d-flex align-items-center header-scrolled">
        <div className="container d-flex justify-content-between align-items-center">

          <div className="logo d-flex align-items-center">
            <Link to="/"><img src='assets/img/logo.png' alt="" className="img-fluid me-2" /></Link>
            <h1><Link to="/">CoreLaser</Link></h1>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li><NavLink activeclassname="active" to="/">Home</NavLink></li>
              <li><NavLink activeclassname="active" to="/about">About</NavLink></li>
              <li><NavLink activeclassname="active" to="/pricing">Pricing</NavLink></li>
              <li><NavLink activeclassname="active" to="/contact">Contact</NavLink></li>

              {
                localStorage.getItem("email")
                  ?
                  <li className="dropdown"><a href="#"><span>{localStorage.getItem("username")}</span> <i className="bi bi-chevron-down"></i></a>
                    <ul>
                      {/* <li><Link to="/payment-log">Payment Log</Link></li> */}
                      <li className='logoutbtn' onClick={() => logout()}>Logout</li>
                    </ul>
                  </li>
                  :
                  <li>
                    <button className='ms-2 btn btn-outline-login' style={{ padding: '5px 15px !important' }} onClick={login}>Sign In</button>
                  </li>
              }


            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNav}></i>
          </nav>

        </div>
      </header>
      <main id="main">
        <section className="section my-5 pb-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-12 mb-5">
                <h2>Terms & Condition</h2>
                <p>
                  PLEASE READ THIS LEGAL DOCUMENT CAREFULLY. Laser Motion
                  Systems, LLC (together with our affiliates, “Laser Gym”, “we”,
                  or “us”) provides an online a back pain treatment guide and
                  related products, services, content and features through Laser
                  Gym mobile, desktop, or device applications (including iOS and
                  Android applications (“Apps”)), websites (the “Laser Gym
                  Site(s)”), and through and Laser Gym-controlled social media
                  pages (including on Facebook, Instagram, Spotify and Twitter).
                  To make these Terms easier to read, the Laser Gym Sites and
                  Apps are collectively called the “Laser Gym Service” or “the
                  Services”. By registering as a user or by visiting, browsing,
                  or using the Laser Gym Service in any way, you (as a “user”)
                  accept and agree to be bound by these Terms of Service
                  (“Terms”), which forms a binding agreement between you and
                  Laser Gym.
                </p>
                <p>
                  PLEASE READ: THESE TERMS CONTAIN A BINDING ARBITRATION
                  PROVISION AND CLASS ACTION WAIVER (SECTION 20). READ
                  CAREFULLY, INCLUDING YOUR RIGHT, IF APPLICABLE, TO OPT OUT OF
                  ARBITRATION. EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN
                  SECTION 20 BELOW, OR WHERE PROHIBITED BY LAW, BY ENTERING INTO
                  THESE TERMS YOU EXPRESSLY AGREE THAT DISPUTES BETWEEN YOU AND
                  LASER GYM WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION,
                  AND YOU HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS
                  ACTION LAWSUIT OR CLASS-⁠WIDE ARBITRATION.
                </p>
                <p>
                  If you do not wish to be bound by these Terms, you may not
                  access or use the Laser Gym Service. Certain elements of the
                  Laser Gym Service may be subject to additional terms and
                  conditions specified from time to time; your use of those
                  elements of the Laser Gym Service is subject to those
                  additional terms and conditions, which are incorporated into
                  these Terms by this reference.
                </p>
                <h3>1. Who May Use the Laser Gym Service</h3>
                <p>
                  Age Requirement. You must be at least 18 years old, or the age
                  of legal majority in your jurisdiction of residence, to
                  register with and use the Laser Gym Service.
                </p>
                <p>
                  We may, in our sole discretion, refuse to offer the Laser Gym
                  Service to any person or entity and change the eligibility
                  criteria at any time. You are solely responsible for ensuring
                  that these Terms are in compliance with all laws, rules and
                  regulations applicable to you, and the right to access and use
                  the Laser Gym Service is revoked where these Terms or use of
                  the Laser Gym Service is prohibited or conflicts with any
                  applicable law, rule or regulation. Further, the Laser Gym
                  Service is offered only for your personal, non-commercial use,
                  and not for the use or benefit of any third party.
                </p>
                <h3>2. License to Use the Laser Gym Service</h3>
                <p>
                  License. Subject to your compliance with these Terms, Laser
                  Gym grants you a limited, non-transferable, non-exclusive,
                  revocable license to access and use the Laser Gym Service for
                  your own personal, non-commercial purposes. This license
                  includes the right to view Content (defined below) available
                  on the Laser Gym Service. This license is personal to you and
                  may not be assigned or sublicensed to anyone else.
                </p>
                <p>
                  Restrictions. Except as expressly permitted in writing by an
                  authorized representative of Laser Gym, you will not
                  reproduce, redistribute, sell, create derivative works from,
                  decompile, reverse engineer, or disassemble the Laser Gym
                  Service, nor will you take any measures to interfere with or
                  damage the Laser Gym Service. All rights not expressly granted
                  by Laser Gym in these Terms are reserved.
                </p>
                <h3>3. Privacy</h3>
                <p>
                  Our Privacy Policy forms a part of these Terms. Please review
                  the Privacy Policy to learn about:
                </p>
                <ul>
                  <li> What information we may collect about you;</li>
                  <li>What we use that information for; and</li>
                  <li>With whom we share that information.</li>
                </ul>
                <h3>4. Membership Requirements Registration</h3>
                <p>
                  To enjoy full access to the Laser Gym Service, you must
                  register as a member of the Laser Gym Service and enter into a
                  subscription agreement for access to our live and on-demand
                  classes, Content and features (a “Subscription”). Your
                  Subscription is governed by the Membership Terms. You must
                  provide complete and accurate registration information to
                  Laser Gym, complete the Subscription process, and notify us if
                  any of your information changes. If you fail to keep your
                  account information up to date, we may have to suspend or
                  terminate your Subscription.
                </p>
                <p>
                  Profile Information and Picture. You may not use someone
                  else’s name, or any name, location, other public profile
                  information or image that violates any third party rights, is
                  against the law, or that is offensive, obscene or otherwise
                  objectionable (in Laser Gym’s sole discretion).
                </p>
                <p>
                  Account Security. You are responsible for all activity that
                  occurs under your account, including any activity by
                  unauthorized users. You may not allow others to use your
                  account. You must safeguard the confidentiality of your
                  password, and if you are using a device that others have
                  access to, log out of your account after using the Laser Gym
                  Service. If you become aware of an unauthorized access to your
                  account, change your password and notify our Support team
                  immediately.
                </p>
                <h3>5. Membership Structure and Fees</h3>
                <p>
                  Laser Gym will provide information on its then-current
                  Subscription requirements on the Laser Gym Site and/or by
                  other means through the Laser Gym Service. Features and prices
                  are subject to change.
                </p>
                <h3>6. Term and Termination; Account Deletion</h3>
                <p>
                  Term. These Terms begin on the date you first use the Laser
                  Gym Service and continue as long as you have an account with
                  us and/or continue to use the Laser Gym Service.
                </p>
                <p>
                  Termination. Laser Gym may, in Laser Gym’s sole discretion,
                  suspend, disable, or delete your account (or any part thereof)
                  or block or remove any User Content (defined below) that you
                  submitted, for any lawful reason, including if Laser Gym
                  determines that you have violated these Terms or that your
                  conduct or User Content would tend to damage Laser Gym’s
                  reputation or goodwill. If Laser Gym deletes your account, you
                  may not re-register for or use the Laser Gym Service under any
                  other user name or profile. Laser Gym may block your access to
                  the Laser Gym Service to prevent re-⁠registration.
                </p>
                <p>
                  Effect of Termination / Account Deletion. Upon termination of
                  these Terms all licenses granted by Laser Gym will terminate.
                  The following sections survive termination: Privacy, User
                  Content, Indemnification, No Warranties, Limitation of
                  Liability, Safety Warnings, Intellectual Property, Arbitration
                  & Class Action Waiver, Governing Law and all general
                  provisions. In the event of account deletion for any reason,
                  User Content may no longer be available and Laser Gym is not
                  responsible for the deletion or loss of such User Content. For
                  clarity, if you cancel your Subscription or it is terminated
                  for any reason, you will lose access to all Content or
                  features provided through the Laser Gym Service. Laser Gym, in
                  its sole discretion, may make available a very limited amount
                  of Content or features to non-subscribers from time to time,
                  and any use of that Content is governed by these Terms.
                </p>
                <h3>7. User Content</h3>
                <p>
                  “Content” means text, graphics, images, music, software,
                  audio, video, works of authorship of any kind, and information
                  or other materials that are posted, generated, provided or
                  otherwise made available through the Laser Gym Service; and
                  (ii) ”User Content” means any content that users (including
                  you) provide to be made available through the Laser Gym
                  Service. Content includes, without limitation, User Content.
                </p>
                <p>
                  Any User Content, whether publicly posted or privately
                  transmitted, is the sole responsibility of the person who
                  originated such User Content. You represent that all User
                  Content submitted by or on behalf of you is accurate,
                  complete, up-to-date, and in compliance with these Terms and
                  with all applicable laws, rules and regulations. You
                  acknowledge that all Content, including User Content, accessed
                  by you using the Laser Gym Service is at your own risk and you
                  will be solely responsible for any damage or loss to you or
                  any other party resulting therefrom. To the full extent
                  permitted by law, we make no representations, warranties or
                  guarantees with respect to any Content that you access on or
                  through the Laser Gym Service.
                </p>
                <p>
                  As between you and Laser Gym, you represent that you own (or
                  have all rights necessary to grant Laser Gym the rights below
                  to) all User Content that you submit to the Laser Gym Service,
                  and that Laser Gym will not need to obtain licenses from any
                  third party or pay royalties to any third party in order to
                  use such User Content. You grant Laser Gym a worldwide,
                  perpetual, irrevocable, non-exclusive, sublicensable (through
                  multiple tiers), transferable royalty-free license and right
                  to use, copy, transmit, distribute, publicly perform and
                  display (through all media now known or later created), edit,
                  modify, and make derivative works from your User Content
                  (including, without limitation, translations) for any purpose
                  whatsoever, commercial or otherwise, without compensation to
                  you. In addition, you waive any so-called “moral rights” or
                  rights of privacy or publicity in your User Content. You
                  further grant all users of the Laser Gym Service permission to
                  view your User Content for their personal, non-commercial
                  purposes. If you make suggestions to Laser Gym or through the
                  Laser Gym Service about improving or adding new features or
                  products to the Laser Gym Service or you otherwise provide
                  feedback or testimonials, you hereby grant to Laser Gym a
                  worldwide, perpetual, irrevocable, non-exclusive,
                  sublicensable (through multiple tiers), transferable
                  royalty-free license and right to use, copy, modify, create
                  derivative works based upon and otherwise exploit your
                  suggestions, feedback and testimonials for any purpose,
                  without any notice, compensation or other obligation to you.
                </p>
                <p>
                  You can remove your User Content by specifically deleting it.
                  However, in certain instances, some of your User Content (such
                  as posts or comments you make) may not be completely removed
                  and copies of your User Content may continue to exist on the
                  Laser Gym Service. We are not responsible or liable for the
                  removal or deletion of (or the failure to remove or delete)
                  any of your User Content.
                </p>
                <h3>8. Rights and Terms for Apps</h3>
                <p>
                  a) Rights in App Granted. Subject to your compliance with
                  these Terms, Laser Gym grants to you a limited non-exclusive,
                  non-transferable, revocable license, with no right to
                  sublicense, to download and install a copy of the App on a
                  mobile device or computer that you own or control and to run
                  such copy of the App solely for your own personal
                  non-commercial purposes. You may not copy the App, except for
                  making a reasonable number of copies for backup or archival
                  purposes. Except as expressly permitted in these Terms, you
                  may not: (i) copy, modify or create derivative works based on
                  the App; (ii) distribute, transfer, sublicense, lease, lend or
                  rent the App to any third party; (iii) reverse engineer,
                  decompile or disassemble the App; or (iv) make the
                  functionality of the App available to multiple users through
                  any means. Laser Gym reserves all rights in and to the App not
                  expressly granted to you under these Terms.
                </p>
                <p>
                  b) Accessing App from an App Store. The following terms apply
                  to any App accessed through or downloaded from any app store
                  or distribution platform (like the Apple App Store or Google
                  Play) where the App may now or in the future be made available
                  (each an “App Provider”). You acknowledge and agree that:
                </p>
                <ul>
                  <li>
                    These Terms are between you and Laser Gym, and not with the
                    App Provider, and Laser Gym (not the App Provider), is
                    solely responsible for the App.
                  </li>
                  <li>
                    The App Provider has no obligation to furnish any
                    maintenance and support services with respect to the App.
                  </li>
                  <li>
                    {" "}
                    In the event of any failure of the App to conform to any
                    applicable warranty, you may notify the App Provider, and
                    the App Provider will refund the purchase price for the App
                    to you (if applicable) and, to the maximum extent permitted
                    by applicable law, the App Provider will have no other
                    warranty obligation whatsoever with respect to the App. Any
                    other claims, losses, liabilities, damages, costs or
                    expenses attributable to any failure to conform to any
                    warranty will be the sole responsibility of Laser Gym.
                  </li>
                  <li>
                    The App Provider is not responsible for addressing any
                    claims you have or any claims of any third party relating to
                    the App or your possession and use of the App, including,
                    but not limited to: (i) product liability claims; (ii) any
                    claim that the App fails to conform to any applicable legal
                    or regulatory requirement; and (iii) claims arising under
                    consumer protection or similar legislation.
                  </li>
                  <li>
                    In the event of any third party claim that the App or your
                    possession and use of that App infringes that third party’s
                    intellectual property rights, Laser Gym will be solely
                    responsible for the investigation, defense, settlement and
                    discharge of any such intellectual property infringement
                    claim to the extent required by these Terms.
                  </li>
                  <li>
                    The App Provider and its affiliates are third-party
                    beneficiaries of these Terms as related to your license to
                    the App, and that, upon your acceptance of the Terms, the
                    App Provider will have the right (and will be deemed to have
                    accepted the right) to enforce these Terms as related to
                    your license of the App against you as a third-party
                    beneficiary thereof.
                  </li>
                  <li>
                    You represent and warrant that (i) you are not located in a
                    country that is subject to a U.S. Government embargo, or
                    that has been designated by the U.S. Government as a
                    terrorist-supporting country; (ii) you are not listed on any
                    U.S. Government list of prohibited or restricted parties;
                    (iii) you are not an individual, or associated with an
                    entity, designated under the UK’s Terrorist Asset-Freezing
                    etc. Act 2010 (TAFA 2010); and (iv) you are not otherwise
                    subject to or affected in any way by any national security
                    or terrorism related rules whether applicable to you
                    personally or to your location or other circumstances.
                  </li>
                  <li>
                    You must also comply with all applicable third party terms
                    of service when using the App.
                  </li>
                </ul>
                <h3>
                  9. General Prohibitions and Laser Gym’s Enforcement Rights
                </h3>
                <p>You agree not to do any of the following:</p>
                <p>
                  a. Post, upload, publish, submit or transmit any User Content
                  that: (i) infringes, misappropriates or violates a third
                  party’s patent, copyright, trademark, trade secret, moral
                  rights or other intellectual property rights, or rights of
                  publicity or privacy; (ii) violates, or encourages any conduct
                  that would violate, any applicable law or regulation or would
                  give rise to civil liability; (iii) is fraudulent, false,
                  misleading or deceptive; (iv) is defamatory, obscene,
                  pornographic, vulgar or offensive; (v) promotes
                  discrimination, bigotry, racism, hatred, harassment or harm
                  against any individual or group; (vi) is violent or
                  threatening or promotes violence or actions that are
                  threatening to any person, animal, or entity; (vii) exploits
                  minors or (viii) promotes illegal or harmful activities or
                  substances;
                </p>
                <p>
                  b. Download and/or install any third party software and/or
                  application on any Laser Gym hardware that is not expressly
                  permitted by Laser Gym in writing;
                </p>
                <p>
                  c. Use, display, mirror or frame the Laser Gym Service or any
                  individual element within the Laser Gym Service, Laser Gym’s
                  name, any Laser Gym trademark, logo or other proprietary
                  information, or the layout and design of any page or form
                  contained on a page, without Laser Gym’s express written
                  consent;
                </p>
                <p>
                  d. Access, tamper with, or use non-public areas of the Laser
                  Gym Service, Laser Gym’s computer systems, or the technical
                  delivery systems of Laser Gym’s providers;
                </p>
                <p>
                  e. Attempt to probe, scan or test the vulnerability of any
                  Laser Gym system or network or breach any security or
                  authentication measures;
                </p>
                <p>
                  f. Avoid, bypass, remove, deactivate, impair, descramble or
                  otherwise circumvent any technological measure implemented by
                  Laser Gym or any of Laser Gym’s providers or any other third
                  party (including another user) to protect the Laser Gym
                  Service or Content;
                </p>
                <p>
                  g. Attempt to access, scrape or search the Laser Gym Service
                  or Content or download Content from the Laser Gym Service,
                  including through the use of any engine, software, tool,
                  agent, device or mechanism (including spiders, robots,
                  crawlers, data mining tools, plugins, add-ons or the like),
                  other than the software and/or search agents provided by Laser
                  Gym or other generally available third-party web browsers;
                </p>
                <p>
                  h. Send any unsolicited or unauthorized advertising,
                  promotional materials, email, junk mail, spam, chain letters
                  or other form of solicitation;
                </p>
                <p>
                  i. Use any meta tags or other hidden text or metadata
                  utilizing a Laser Gym trademark, logo URL or product name
                  without Laser Gym’s express written consent;
                </p>
                <p>
                  j. Use the Laser Gym Service or Content, or any portion
                  thereof, for any commercial purpose or for the benefit of any
                  third party or in any manner not permitted by these Terms or
                  permitted expressly in writing by Laser Gym;
                </p>
                <p>
                  k. Forge any TCP/IP packet header or any part of the header
                  information in any email or newsgroup posting, or in any way
                  use the Laser Gym Service or Content to send altered,
                  deceptive or false source-identifying information;
                </p>
                <p>
                  l. Attempt to decipher, decompile, disassemble or reverse
                  engineer any of the software used to provide the Laser Gym
                  Service or Content;
                </p>
                <p>
                  m. Interfere with, or attempt to interfere with, the access of
                  any user, host or network, including, without limitation,
                  sending a virus, overloading, flooding, spamming, or
                  mail-bombing the Laser Gym Service;
                </p>
                <p>
                  n. Collect or store any personally identifiable information
                  from the Laser Gym Service from other users of the Laser Gym
                  Service without their express permission;
                </p>
                <p>
                  o. Copy, use, disclose or distribute any information obtained
                  from the Laser Gym Service, whether directly or through third
                  parties (such as search engines), without Laser Gym’s express
                  written consent;
                </p>
                <p>
                  p. Impersonate or misrepresent your affiliation with any
                  person or entity;
                </p>
                <p>q. Violate any applicable law or regulation; or</p>
                <p>
                  r. Encourage or enable any other individual to do any of the
                  foregoing.
                </p>
                <p>
                  Although we’re not obligated to monitor access to or use of
                  the Laser Gym Service or Content or to review or edit any
                  Content, we have the right to do so for the purpose of
                  operating the Laser Gym Service, to ensure compliance with
                  these Terms, to comply with applicable law or other legal
                  requirements and to maintain the integrity and reputation of
                  the Laser Gym Service and Laser Gym’s systems. We reserve the
                  right, but are not obligated, to remove or disable access to
                  any Content, at any time and without notice, including, but
                  not limited to, if we, at our sole discretion, consider any
                  Content to be objectionable or in violation of these Terms. We
                  have the right to investigate violations of these Terms or
                  conduct that affects the Laser Gym Service. We may also
                  consult and cooperate with law enforcement authorities to
                  prosecute users who violate the law.
                </p>
                <h3>10. Indemnification</h3>
                <p>
                  You agree to indemnify, defend, and hold harmless Laser Gym
                  and its directors, officers, employees and agents, from and
                  against all claims, damages, losses and costs that arise from
                  or relate to (i) your activities on the Laser Gym Service,
                  (ii) any User Content submitted by or on behalf of you or
                  (iii) your violation of these Terms.
                </p>
                <h3>11. Third Party Links and Content</h3>
                <p>
                  There may be links on the Laser Gym Service that let you leave
                  the particular Laser Gym Service you are accessing in order to
                  access a linked site that is operated by a third party. Laser
                  Gym neither controls nor endorses these sites, nor has Laser
                  Gym reviewed or approved the content that appears on them.
                  Laser Gym is not responsible for the legality, accuracy or
                  inappropriate nature of any content, advertising, products or
                  other materials on or available from any such third party
                  sites. You acknowledge and agree that Laser Gym is not
                  responsible or liable, directly or indirectly, for any damage
                  or loss caused or alleged to be caused by or in connection
                  with the access or use of any of the links, content, goods or
                  services available on or through these third party sites.
                </p>
                <h3>12. No Warranties</h3>
                <p>
                  Laser Gym reserves the right to modify the Laser Gym Service,
                  including, but not limited to updating, adding to, enhancing,
                  modifying, removing or altering any Content or features of the
                  Laser Gym Service, at any time, in its sole discretion. You
                  are responsible for providing your own access (e.g., computer,
                  mobile device, Internet connection, etc.) to the Laser Gym
                  Service. Laser Gym has no obligation to screen or monitor any
                  Content and does not guarantee that any Content available on
                  the Laser Gym Service is suitable for all users or that it
                  will continue to be available for any length of time.
                </p>
                <p>
                  Laser Gym provides the Laser Gym Service on an “AS IS” and “AS
                  AVAILABLE” basis. You therefore use the Laser Gym Service at
                  your own risk. Other than as expressly provided in writing by
                  Laser Gym in connection with your purchase of a Laser Gym
                  product, to the extent permitted by law, Laser Gym expressly
                  disclaims any and all warranties of any kind, whether express
                  or implied, including, but not limited to implied warranties
                  of merchantability, fitness for a particular purpose,
                  non-infringement, quiet enjoyment and any other warranty that
                  might arise under any law. Without limiting the foregoing,
                  Laser Gym makes no representations or warranties:
                </p>
                <ul>
                  <li>
                    · That the Laser Gym Service is or will be permitted in your
                    jurisdiction;
                  </li>
                  <li>
                    · That the Laser Gym Service will be uninterrupted or
                    error-⁠free;
                  </li>
                  <li>· Concerning any Content, including User Content;</li>
                  <li>
                    · Concerning any third party’s use of User Content that you
                    submit;
                  </li>
                  <li>
                    · That the Laser Gym Service will meet your personal or
                    professional needs;
                  </li>
                  <li>
                    · That Laser Gym will continue to support any particular
                    feature of the Laser Gym Service.
                  </li>
                  <li>
                    · Concerning sites and resources outside of the Laser Gym
                    Service, even if linked to from the Laser Gym Service.
                  </li>
                </ul>
                <p>
                  To the extent that another party may have access to or view
                  Content on your device, you are solely responsible for
                  informing such party of all disclaimers and warnings in these
                  Terms. TO THE EXTENT ANY DISCLAIMER OR LIMITATION OF LIABILITY
                  DOES NOT APPLY, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL
                  APPLICABLE EXPRESS, IMPLIED, AND STATUTORY WARRANTIES WILL BE
                  LIMITED IN DURATION TO A PERIOD OF THIRTY DAYS AFTER THE DATE
                  ON WHICH YOU FIRST USED THE LASER GYM SERVICE, AND NO
                  WARRANTIES SHALL APPLY AFTER SUCH PERIOD.
                </p>
                <h3>13. Limitation of Liability</h3>
                <p>
                  To the fullest extent permitted by law: (i) Laser Gym shall
                  not be liable for any indirect, incidental, special,
                  consequential, punitive, or exemplary damages, including but
                  not limited to damages for loss of profits, economic or pure
                  economic losses, goodwill, use, data, service interruption,
                  computer damage, system failure, inability to use the Laser
                  Gym Service or Content or other intangible losses, even if a
                  limited remedy set forth herein is found to have failed its
                  essential purpose; and (ii) Laser Gym’s total liability to you
                  for all claims, in the aggregate, will not exceed the amount
                  actually paid by you to Laser Gym over the twelve months
                  preceding the date your first claim(s) arose. If you live in a
                  jurisdiction that does not allow the exclusion or limitation
                  of liability for consequential or incidental damages, the
                  above limitation does not apply to you. To the extent that one
                  or any aspect of Laser Gym’s limitations set out above does
                  not apply, all remaining aspects survive. The exclusions and
                  limitations of damages set forth above are fundamental
                  elements of the basis of the bargain between Laser Gym and
                  you.
                </p>
                <h3>14. Safety Warnings</h3>
                <p>
                  THE LASER GYM SERVICE OFFERS HEALTH AND FITNESS INFORMATION
                  AND IS DESIGNED FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES
                  ONLY. YOU SHOULD CONSULT YOUR PHYSICIAN OR GENERAL
                  PRACTITIONER BEFORE BEGINNING A NEW FITNESS PROGRAM. YOU
                  SHOULD NOT RELY ON THIS INFORMATION AS A SUBSTITUTE FOR, NOR
                  DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR
                  TREATMENT. IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR
                  HEALTH, YOU SHOULD ALWAYS CONSULT WITH A PHYSICIAN, GENERAL
                  PRACTITIONER OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT
                  DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED
                  ADVICE FROM YOUR HEALTHCARE PROFESSIONAL BECAUSE OF SOMETHING
                  YOU MAY HAVE READ ON THE LASER GYM SITE OR HEARD ON THE LASER
                  GYM SERVICE. THE USE OF INFORMATION PROVIDED THROUGH THE LASER
                  GYM SERVICE IS SOLELY AT YOUR OWN RISK AND IS NOT MEDICAL OR
                  HEALTHCARE ADVICE.
                </p>
                <p>
                  NOTHING STATED OR POSTED ON THE LASER GYM SITE OR AVAILABLE
                  THROUGH ANY LASER GYM SERVICE IS INTENDED TO BE, AND MUST NOT
                  BE TAKEN TO BE, THE PRACTICE OF MEDICAL OR COUNSELING CARE.
                  FOR PURPOSES OF THESE TERMS, THE PRACTICE OF MEDICINE AND
                  COUNSELING INCLUDES, WITHOUT LIMITATION, PSYCHIATRY,
                  PSYCHOLOGY, PSYCHOTHERAPY, OR PROVIDING HEALTH CARE TREATMENT,
                  INSTRUCTIONS, DIAGNOSIS, PROGNOSIS OR ADVICE. THE LASER GYM
                  SERVICE IS CONTINUALLY UNDER DEVELOPMENT AND, TO THE FULL
                  EXTENT PERMITTED BY LAW, LASER GYM MAKES NO WARRANTY OF ANY
                  KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR
                  APPROPRIATENESS FOR ANY PURPOSE. IN THAT REGARD, DEVELOPMENTS
                  IN MEDICAL RESEARCH MAY IMPACT THE HEALTH, FITNESS AND
                  NUTRITIONAL ADVICE THAT APPEARS THROUGH THE LASER GYM SERVICE.
                  NO ASSURANCE CAN BE GIVEN THAT THE ADVICE CONTAINED IN THE
                  LASER GYM SERVICE WILL ALWAYS INCLUDE THE MOST RECENT FINDINGS
                  OR DEVELOPMENTS WITH RESPECT TO THE PARTICULAR MATERIAL.
                </p>
                <p>
                  In becoming a member of Laser Gym with the intent of using the
                  Laser Gym Service, you affirm that either (A) all of the
                  following statements are true: (i) no physician or general
                  practitioner has ever informed you that you have a heart
                  condition or that you should only do physical activities
                  recommended by a physician or general practitioner; (ii) you
                  have never felt chest pain when engaging in physical activity;
                  (iii) you have not experienced chest pain when not engaged in
                  physical activity at any time within the past month; (iv) you
                  have never lost your balance because of dizziness and you have
                  never lost consciousness; (v) you do not have a bone or joint
                  problem that could be made worse by a change in your physical
                  activity; (vi) your physician or general practitioner is not
                  currently prescribing drugs for your blood pressure or heart
                  condition; (vii) you do not have a history of high blood
                  pressure, and no one in your immediate family has a history of
                  high blood pressure or heart problems; and (viii) you do not
                  know of any other reason you should not exercise; or (B) your
                  physician or general practitioner has been specifically
                  consulted by you and approved of your use of the Laser Gym
                  Service.
                </p>
                <p>
                  You further affirm that (a) you are not pregnant,
                  breastfeeding or lactating; unless (b) your physician or
                  general practitioner has been specifically consulted and
                  approved your use of the Laser Gym Service.
                </p>
                <p>
                  Laser Gym reserves the right to refuse or cancel your usership
                  if we determine that you have certain medical conditions or
                  that the representations set forth above are untrue in any
                  respect.
                </p>
                <h3>15. Intellectual Property</h3>
                <p>
                  You acknowledge that the Laser Gym Service contains software
                  and other content that is protected by copyrights, patents,
                  trademarks, trade secrets or other proprietary rights, and
                  that these rights are valid and protected in all forms, media
                  and technologies existing now or hereafter developed. All
                  Laser Gym-generated content, and content provided to Laser Gym
                  by its partners and licensors, is copyrighted individually
                  and/or as a collective work under the U.S. copyright laws and
                  all applicable international copyright (or equivalent) laws in
                  all jurisdictions; further, as between you and Laser Gym,
                  Laser Gym owns a copyright in the selection, coordination,
                  arrangement and enhancement of all content in the Laser Gym
                  Service. Subject to your compliance with these Terms, and
                  solely for so long as you are permitted by us to access and
                  use the Laser Gym Service, you may download one copy of the
                  application to any single device for your personal,
                  non-commercial home use only, provided you keep intact all
                  copyright and other proprietary notices, and are in compliance
                  with these Terms. Subject to your compliance with these Terms,
                  and solely for so long as you are permitted by us to access
                  and use the Laser Gym Service, Laser Gym hereby grants you a
                  limited, revocable, non-exclusive, non-transferable right and
                  license to access and use the content made available on the
                  Laser Gym Service for your personal, non-commercial use of the
                  Laser Gym Service and for no other purpose whatsoever. Unless
                  otherwise specified, copying or modifying any content or using
                  content for any purpose other than your personal,
                  non-commercial use of the Laser Gym Service, including use of
                  any such content on any other website or networked computer
                  environment, is strictly prohibited.
                </p>
                <p>
                  The Laser Gym name, logos and affiliated properties, designs
                  and marks are the exclusive property of Laser Gym and/or its
                  affiliates, whether registered or unregistered, and may not be
                  used in connection with any product or service that is not
                  ours, or in any manner that is likely to cause confusion as to
                  our endorsement, affiliation or sponsorship of any person,
                  product or service. Nothing contained on the Laser Gym Service
                  should be construed as granting, by implication, estoppel or
                  otherwise, any license or right to use any of our trade names,
                  trademarks or other intellectual property without our express
                  prior written consent.
                </p>
                <p>
                  Any other trademarks appearing on the Laser Gym Service are
                  trademarks of their respective owners. Our partners or service
                  providers may also have additional proprietary rights in the
                  content that they make available through the Laser Gym
                  Service. All rights not expressly granted in these Terms are
                  reserved.
                </p>
                <h3>16. Copyright / DMCA Policy</h3>
                <p>
                  Laser Gym respects the intellectual property of others, and we
                  ask our users to do the same. Each user is responsible for
                  ensuring that their User Content does not infringe any third
                  party copyright or other intellectual property rights.
                </p>
                <p>
                  If properly notified that any materials infringe a third
                  party’s copyright, Laser Gym will promptly remove such
                  materials from the Laser Gym Site in accordance with the U.S.
                  Digital Millennium Copyright Act (“DMCA”), the U.K. Copyright
                  Designs and Patents Act 1988 (“CDPA”), the U.K. Digital
                  Economy Act 2010 (“DEA”), or equivalent laws which are
                  applicable in other jurisdictions. In addition, Laser Gym may,
                  when appropriate, terminate the accounts of repeat copyright
                  infringers.
                </p>
                <p>
                  Filing a DMCA, CDPA or DEA Notice (or equivalent legal
                  notice), to Remove Copyrighted Content - for Copyright
                  Holders:
                </p>
                <p>
                  If you believe that your work has been copied in a way that
                  constitutes copyright infringement, please provide us with a
                  written notice containing the following information:
                </p>
                <p>
                  1. Your name, address, telephone number, and email address.
                </p>
                <p>
                  2. A description of the copyrighted work that you claim has
                  been infringed.
                </p>
                <p>
                  3. A description of where on the Laser Gym Site the material
                  that you claim is infringing may be found, sufficient for
                  Laser Gym to locate the material (e.g., the URL).
                </p>
                <p>
                  4. A statement that you have a good faith belief that the use
                  of the copyrighted work is not authorized by the copyright
                  owner, its agent, or the law.
                </p>
                <p>
                  5. A statement by you UNDER PENALTY OF PERJURY that the
                  information in your notice is accurate and that you are the
                  copyright owner or authorized to act on the copyright owner’s
                  behalf.
                </p>
                <p>6. Your electronic or physical signature.</p>
                <p>
                  You may submit this information, or any counternotice, via:
                </p>
                <ul>
                  <li>
                    · Email, with the subject line “Copyright Notices” to:
                    copyrights@Laser Gym.com
                  </li>
                  <li>· Offline:</li>
                </ul>
                <p>Laser Gym </p>
                <p>4530 Via Esperanza</p>
                <p>Santa Barbara, CA 93110</p>
                <p>Attn: Copyright Agent</p>
                <p>
                  Laser Gym may disclose any communications concerning DMCA,
                  CPDA, DEA or other applicable copyright notices or other
                  intellectual property complaints with third parties, including
                  the users who have posted the allegedly infringing material.
                </p>
                <p>
                  If you have questions about the legal requirements of a DMCA
                  notice, please contact an attorney or see Section 512(c)(3) of
                  the U.S. Copyright Act, 17 U.S.C. § 512(c)(3), for more
                  information. If you have questions about the legal
                  requirements of a DMCA counter-notification, please contact an
                  attorney or see Section 512(g)(3) of the U.S. Copyright Act,
                  17 U.S.C. § 512(g)(3), for more information.
                </p>
                <p>
                  If you have questions about the legal requirements of a CPDA,
                  DEA or other applicable copyright notice, you should consult a
                  legal advisor such as a solicitor and obtain professional
                  legal advice and/or representation.
                </p>
                <h3>
                  17. ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT –
                  PLEASE REVIEW AS THIS MAY AFFECT YOUR LEGAL RIGHTS. APPLICABLE
                  TO THE FULL EXTENT PERMITTED BY LAW.
                </h3>
                <p>
                  a. Mandatory Arbitration of Disputes. You and Laser Gym each
                  agree that any dispute, claim or controversy arising out of or
                  relating to these Terms or the breach, termination,
                  enforcement, interpretation or validity thereof or the use of
                  the Services or Content (collectively, “Disputes”) will be
                  resolved solely by binding, individual arbitration and not in
                  a class, representative or consolidated action or proceeding.
                  You and Laser Gym agree that the U.S. Federal Arbitration Act
                  (or equivalent laws in the jurisdiction in which the Laser Gym
                  entity that you have contracted with is incorporated) governs
                  the interpretation and enforcement of these Terms and that you
                  and Laser Gym are each waiving the right to a trial by jury or
                  to participate in a class action. This arbitration provision
                  shall survive termination of these Terms.
                </p>
                <p>
                  b. Exceptions and Opt-out. As limited exceptions to Section
                  17(a) above: (i) you may seek to resolve a Dispute in small
                  claims court if it qualifies; and (ii) we each retain the
                  right to seek injunctive or other equitable relief from a
                  court to prevent (or enjoin) the infringement or
                  misappropriation of our intellectual property rights. In
                  addition, you will retain the right to opt out of arbitration
                  entirely and litigate any Dispute if you provide us with
                  written notice of your desire to do so by regular mail sent to
                  the attention of Laser Gym’s Legal Department at the Laser Gym
                  address set out below within thirty days following the date
                  you first agree to these Terms.
                </p>
                <p>
                  c. Conducting Arbitration and Arbitration Rules. The
                  arbitration will be conducted by the American Arbitration
                  Association (“AAA”) under its Consumer Arbitration Rules (the
                  “AAA Rules”) then in effect, except as modified by these
                  Terms. The AAA Rules are available at{" "}
                  <a href="https://www.adr.org/" target='_blank'>
                    www.adr.org
                  </a>{" "}
                  or by calling 1-800-778-7879. A party who wishes to start
                  arbitration must submit a written Demand for Arbitration to
                  AAA and give notice to the other party as specified in the AAA
                  Rules. The AAA provides a form Demand for Arbitration at
                  <a href="https://www.adr.org/" target='_blank'>
                    www.adr.org
                  </a>
                  .
                </p>
                <p>
                  If your claim is for U.S. $10,000 or less, you may choose
                  whether the arbitration will be conducted solely on the basis
                  of documents submitted to the arbitrator, through a telephonic
                  or video-conference hearing, or by an in-person hearing as
                  established by the AAA Rules. If your claim exceeds U.S.
                  $10,000, the right to a hearing will be determined by the AAA
                  Rules. Any arbitration hearings will take place in the county
                  (or parish) where you live, unless we both agree to a
                  different location. The parties agree that the arbitrator
                  shall have exclusive authority to decide all issues relating
                  to the interpretation, applicability, enforceability and scope
                  of this arbitration agreement.
                </p>
                <p>
                  d. Arbitration Costs. Payment of all filing, administration
                  and arbitrator fees will be governed by the AAA Rules. Laser
                  Gym will pay for all filing, administration and arbitrator
                  fees and expenses if your Dispute is for less than U.S.
                  $10,000, unless the arbitrator finds your Dispute to be
                  frivolous. If we prevail in arbitration, we will pay all of
                  our attorneys’ fees and costs and won’t seek to recover them
                  from you. If you prevail in arbitration you will be entitled
                  to an award of attorneys’ fees and expenses to the extent
                  provided under applicable law.
                </p>
                <p>
                  e. Class Action Waiver. YOU AND LASER GYM AGREE THAT EACH OF
                  US MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN INDIVIDUAL
                  CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                  PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the
                  parties’ dispute is resolved through arbitration, the
                  arbitrator may not consolidate another person’s claims with
                  your claims and may not otherwise preside over any form of a
                  representative or class proceeding. If this specific provision
                  is found to be unenforceable, then the entirety of this
                  Section 17 shall be null and void.
                </p>
                <p>
                  f. Effect of Changes on Arbitration. Notwithstanding the
                  provisions of Section 22 “Modification”, if Laser Gym changes
                  any of the terms of this Section 17 after the date you first
                  accepted these Terms (or accepted any subsequent changes to
                  these Terms), you may reject any such change by sending us
                  written notice within thirty days of the date such change
                  became effective, as indicated in the “Last Updated” date
                  above or the date of Laser Gym’s email to you notifying you of
                  such change. By rejecting any change, you are agreeing that
                  you will arbitrate any Dispute between you and Laser Gym in
                  accordance with the terms of this Section 20 as of the date
                  you first accepted these Terms (or accepted any subsequent
                  changes to these Terms).
                </p>
                <p>
                  g. Severability. With the exception of any of the provisions
                  in Section 17(e) above, if an arbitrator or court of competent
                  jurisdiction decides that any part of these Terms is invalid
                  or unenforceable, the other parts of these Terms will still
                  apply.
                </p>
                <h3>
                  18. Contracting Entities, Governing Law and Jurisdiction
                </h3>
                <p>
                  These Terms shall be governed by the laws of the State of
                  California, United States of America, without regard to
                  principles of conflicts of law. The Uniform Commercial Code,
                  the Uniform Computer Information Transaction Act, and the
                  United Nations Convention of Controls for International Sale
                  of Goods shall not apply.
                </p>
                <p>
                  Subject to the agreements in Section 17 above, (i) exclusive
                  jurisdiction for all Disputes that are not required to be
                  arbitrated will be the state and federal courts located in
                  Santa Barbara, California, United States of America, and you
                  consent to the jurisdiction of those courts.
                </p>
                <h3>19. Interpretation; Severability; Waiver; Remedies</h3>
                <p>
                  Headings are for convenience only and shall not be used to
                  construe these Terms. If any term of these Terms is found
                  invalid or unenforceable by any court of competent
                  jurisdiction, that term will be severed from these Terms. No
                  failure or delay by Laser Gym in exercising any right
                  hereunder will waive any further exercise of that right. The
                  waiver of any such right or provision will be effective only
                  if in writing and signed by a duly authorized representative
                  of Laser Gym. Laser Gym’s rights and remedies hereunder are
                  cumulative and not exclusive.
                </p>
                <h3>
                  20. Successors; Assignment; No Third Party Beneficiaries
                </h3>
                <p>
                  These Terms are binding upon and will inure to the benefit of
                  both parties and their respective successors, heirs,
                  executors, administrators, personal representatives, and
                  permitted assigns. You may not assign or transfer these Terms
                  without Laser Gym’s prior written consent. Laser Gym may
                  assign its rights, obligations and/or these Terms at any time
                  in its sole discretion without notice to you.
                </p>
                <h3>21. Notices</h3>
                <p>
                  You consent to receive all communications including notices,
                  agreements, disclosures, or other information from Laser Gym
                  electronically. Laser Gym may communicate by email or by
                  posting to the Laser Gym Service. For support-related
                  inquiries, you may email Support. For all other notices to
                  Laser Gym, write to the following addresses:
                </p>
                <p>Laser Gym</p>
                <p>4530 Via Esperanza</p>
                <p>Santa Barbara, CA 93110</p>
                <p>USA</p>
                <p>Attn: Legal Department</p>
                <p>
                  Nothing in these Terms or otherwise limits Laser Gym’s right
                  to object to subpoenas, claims, or other demands.
                </p>
                <h3>22. Modification</h3>
                <p>
                  We may update these Terms at any time, in our sole discretion.
                  If we do so, we’ll let you know by, at a minimum, posting the
                  updated Terms (as indicated by a revised “Last Updated” date
                  at the top of this page) on the Laser Gym Site and/or through
                  the Laser Gym Service. Modifications will be effective on the
                  date that they are posted to the Laser Gym Site. It’s
                  important that you review the Terms whenever we update them
                  before you use the Laser Gym Service. If you continue to use
                  the Laser Gym Service after we have posted updated Terms, you
                  are agreeing to be bound by the updated Terms. If you don’t
                  agree to be bound by the updated Terms, then, except as
                  otherwise provided in Section 17(f) “Effect of Changes on
                  Arbitration,” you may not use the Laser Gym Service anymore.
                  Because the Laser Gym Service is evolving over time we may
                  change or discontinue all or any part of the Laser Gym
                  Service, at any time and without notice, at our sole
                  discretion.
                </p>
                <h3>23. Entire Agreement</h3>
                <p>
                  These Terms incorporate the following legal documents by
                  reference, as well as any other policies or procedures
                  referenced herein that are posted to the Laser Gym Site from
                  time to time:
                </p>
                <ul>
                  <li>Privacy Policy</li>
                  <li>Membership Terms</li>
                </ul>
                <p>
                  In the event of a conflict between any policies posted on the
                  Laser Gym Service and these Terms, these Terms will control.
                  These Terms represents the entire understanding between Laser
                  Gym and you regarding the Laser Gym Service or Content and
                  supersede all prior agreements and understandings regarding
                  the same. If any provision of these Terms is held invalid or
                  unenforceable by an arbitrator or a court of competent
                  jurisdiction, that provision will be enforced to the maximum
                  extent permissible and the other provisions of these Terms
                  will remain in full force and effect.
                </p>
                <h3>24. Force Majeure</h3>
                <p>
                  Neither Party shall be liable for any failure or delay in
                  performance under these terms (other than for delay in the
                  payment of money due and payable in accordance with these
                  terms) for causes beyond the party’s reasonable control and
                  not caused by that party’s fault, or negligence, including,
                  but not limited to, “acts of God”, acts of government, flood,
                  fire, civil unrest, acts of terror, strikes or other labour
                  problems, computer attacks or malicious acts, such as attacks
                  on or through the internet, any internet service provider,
                  telecommunications or hosting facility, but in each case, only
                  if and to the extent that the non-performing Party is without
                  fault in causing such failure or delay, and the failure or
                  delay could not have been prevented by reasonable precautions
                  and measures and cannot reasonably be circumvented by the
                  non-conforming Party through the use of alternate sources,
                  workaround plans, disaster recovery, business continuity
                  measures or other means. Dates by which performance
                  obligations are scheduled to be met will be extended for a
                  period of time equal to the time lost due to any delay so
                  caused.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <Footer /> */}
      <div className="row justify-content-center text-center">
        <div className="col-md-7">
          <p className="copyright">&copy; {new Date().getFullYear()} Copyright CoreLaser. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
