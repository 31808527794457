//Live

const API_BASE_URL = 'https://api.corelaser.co/auth';
const Payment_BASE_URL = 'https://api.corelaser.co/payment';
const news_BASE_URL = 'https://api.corelaser.co/news';
const testimonial_URL = 'https://api.corelaser.co'

//Local

// const API_BASE_URL = 'http://192.168.1.53:3000/auth';
// const Payment_BASE_URL = 'http://192.168.1.53:3000/payment';
// const news_BASE_URL = 'http://192.168.1.53:3000/news';
// const testimonial_URL = 'http://192.168.1.53:3000'


export const signIn = (endPoint, data) => {
  console.log(endPoint, data);
  return fetch(`${API_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then((data) => data.json())

};

export const addNewsLatter = (endPoint, data) => {
  console.log(endPoint, data);
  return fetch(`${news_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then((data) => data.json())

};

export const getTerminal = (endPoint, data) => {
  console.log(endPoint, data);
  return fetch(`${Payment_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then((data) => data.json())

};

export const MakePaymentWeb = (endPoint, data) => {
  console.log("Log from helper ->>", endPoint, data);


  return fetch(`${Payment_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
    method: 'POST',
  }).then((data) => data.json())

};

export const signUp = async (endPoint, data) => {

  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  console.log(endPoint, data);
  return fetch(`${API_BASE_URL}/${endPoint}`, {

    method: 'POST',
    body: formData,
  }).then((data) => data.json())
};

export const forgotPassword = async (endPoint, data) => {

  return fetch(`${API_BASE_URL}/${endPoint}`, {

    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const verifyOTP = async (endPoint, data) => {
  return fetch(`${API_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const resetPassword = async (endPoint, data) => {
  return fetch(`${API_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const testimonials = (endPoint) => {
  console.log("THIS IS ENDPOINT -- : ", `${testimonial_URL}/${endPoint}`);

  return fetch(`${testimonial_URL}/${endPoint}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

    },
    method: "GET",
  }).then((data) => data.json());
};

